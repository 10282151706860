import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { OmitStylesHTMLAttributes } from '../../types';

export const badgeVariants = cva(
    'rocco-badge inline-flex items-center rounded-full min-w-0 transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
    {
        variants: {
            color: {
                default: 'bg-brick-800 text-primary',
                secondary: 'bg-secondary-surface text-secondary-foreground',
                destructive: 'bg-destructive text-destructive-foreground',
                outline: 'text-foreground',
                dark: 'bg-gray-800 text-primary',
            },
            size: {
                default: 'px-4 py-2',
                square: 'size-4.5 justify-center pr-px',
                compact: 'p-1 h-4.5 min-w-4.5',
                centeredCompact: 'justify-center h-4.5 min-w-4.5 p-1',
            },
            text: {
                default: 'text-xs font-medium',
                small: 'text-xs font-medium md:tracking-wider',
            },
        },
        defaultVariants: {
            color: 'default',
            size: 'default',
        },
    },
);

const _badgeContentVariants = cva('text-ellipsis whitespace-nowrap', {
    variants: {
        contentOverflow: {
            hidden: 'overflow-hidden',
            visible: 'overflow-visible',
        },
    },
    defaultVariants: {
        contentOverflow: 'hidden',
    },
});

interface BadgeProps
    extends OmitStylesHTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants>,
        VariantProps<typeof _badgeContentVariants> {}

export const Badge = ({
    children,
    color,
    size,
    text,
    contentOverflow,
    ...props
}: BadgeProps) => {
    return (
        <div className={badgeVariants({ color, size, text })} {...props}>
            <span
                className={_badgeContentVariants({
                    contentOverflow,
                })}
            >
                {children}
            </span>
        </div>
    );
};
