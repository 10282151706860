/**
 * Generate the indication text in the scroll indicator to scroll.
 * @param {number} number - The number to convert.
 * @param {boolean} roundedByThousand - True if the number should be rounded (remove values < 1000>).
 */
export const formatToThousandsDelimiter = (
    number: number,
    roundedByThousand: boolean = false,
) => {
    const scrollToProjectsCount = roundedByThousand
        ? Math.floor(number / 1000) * 1000
        : number;

    return new Intl.NumberFormat('en-US', {}).format(scrollToProjectsCount);
};

/**
 * Formats a number with comma separators for thousands.
 * @param number The number to format.
 * @returns A string representation of the number with comma separators.
 */
export function formatNumberWithCommas(number: number): string {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Formats a number with comma separators for thousands and optional decimal places.
 * @param number The number to format.
 * @param decimalPlaces The number of decimal places to show (default is 0).
 * @returns A string representation of the number with comma separators and specified decimal places.
 */
export function formatNumberWithCommasAndDecimals(
    number: number,
    decimalPlaces: number = 0,
): string {
    return number.toLocaleString('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    });
}

/**
 * Rounds a number to the closest thousand or hundred.
 * @param number The number to round.
 * @param toThousand If true, rounds to the nearest thousand; if false, rounds to the nearest hundred.
 * @param roundUp If true, rounds up; if false, rounds down.
 * @returns The rounded number.
 */
export function roundToNearest(
    number: number,
    toThousand: boolean = true,
    roundUp: boolean = false,
): number {
    const factor = toThousand ? 1000 : 100;

    if (number < factor) {
        return number;
    }

    return roundUp
        ? Math.ceil(number / factor) * factor
        : Math.floor(number / factor) * factor;
}

/**
 * Converts a number to a shortened string representation.
 * Returns:
 *  - '0' for numbers less than 1
 *  - Exact number for 1-999
 *  - Rounded to 1 decimal place + 'K' for 1,000-9,999
 *  - Rounded down + 'K' for 10,000-999,999
 *  - Rounded to 1 decimal place + 'M' for 1,000,000 and above
 *
 * @param num - The number to be shortened.
 * @returns A string representation of the number.
 */
export const shortenCount = (num: number): string => {
    if (num < 1) {
        return '0';
    }

    if (num < 1000) {
        return num.toString();
    }

    if (num < 10000) {
        return (num / 1000).toFixed(num % 1000 === 0 ? 0 : 1) + 'K';
    }

    if (num < 1000000) {
        return Math.floor(num / 1000) + 'K';
    }

    return (num / 1000000).toFixed(num % 1000000 === 0 ? 0 : 1) + 'M';
};
