import React, { useEffect, useRef } from 'react';
import { cn } from '../../../utils/cn';

import * as animateCssGrid from 'animate-css-grid';

const { wrapGrid } = animateCssGrid;

type WrapGridArguments = Parameters<typeof wrapGrid>[1];

interface GridProps extends React.PropsWithChildren {
    colClasses?: string;
    rowsGapClass?: string;
    animate?: boolean | WrapGridArguments;
}

const defaultAnimateOptions: WrapGridArguments = {
    duration: 300,
    easing: 'circOut',
};

export const Grid = React.forwardRef<HTMLDivElement, GridProps>(
    (props, ref) => {
        const {
            children,
            colClasses = 'grid-cols-1',
            rowsGapClass,
            animate,
        } = props;

        const innerRef = useRef<HTMLDivElement>(null);
        const gridRef = ref || innerRef;

        useEffect(() => {
            if (gridRef && 'current' in gridRef && gridRef.current && animate) {
                wrapGrid(
                    gridRef.current,
                    typeof animate === 'object'
                        ? animate
                        : defaultAnimateOptions,
                );
            }
        }, [gridRef, animate]);

        return (
            <div
                ref={gridRef}
                className={cn('grid gap-6', colClasses, rowsGapClass)}
            >
                {children}
            </div>
        );
    },
);

Grid.displayName = 'Grid';
