import { useEffect } from 'react';
import { atom, useSetAtom } from 'jotai';

export interface TilesTrackingConfig {
    /**
     * Callback fired when the tile is selected (clicked).
     */
    onClick?: (
        targetRef: React.RefObject<HTMLElement>, // we need it for calculating the tile position
        itemID?: number,
        professionalID?: number,
        section?: string,
    ) => void;

    /**
     * Callback fired when the category is selected (clicked).
     */
    onCategoryClick?: (categoryID: number) => void;

    onTileMediaSliderSlideChange?: (
        direction: 'CarouselPrev' | 'CarouselNext',
        itemID?: number,
    ) => void;
}

/**
 * Context interface for managing tiles actions and interactions.
 * Provides methods for tracking tile configurations.
 * @interface TileActionsContextType
 */
interface TileActionsContextType {
    trackingConfig?: TilesTrackingConfig;
}

/**
 * Atoms for managing tiles actions context.
 * `localContextAtom` stores the core context data privately.
 * `uiContextAtomTilesActions` provides read-only access to the context data.
 */
export const uiContextAtomTileActions = atom(get => get(localContextAtom));
const localContextAtom = atom<TileActionsContextType>();

interface UseSharedTilesProps {
    trackingConfig?: TilesTrackingConfig;
}

export const useSharedTiles = (props: UseSharedTilesProps) => {
    const { trackingConfig } = props;
    const setContext = useSetAtom(localContextAtom);

    useEffect(() => {
        setContext({ trackingConfig });
    }, [setContext, trackingConfig]);
};
